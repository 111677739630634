import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { ISettings } from "../../types/ISettings";
import TermsOfUse from "../Layout/TermsOfUse/TermsOfUse";
import MerchantTermsAndConditions from "../Layout/MerchantTermsAndConditions/MerchantTermsAndConditions";
import PrivacyPolicy from "../Layout/PrivacyPolicy/PrivacyPolicy";
import { IApplicationState, IInternationalization } from "../../types";
import { updateAccepted } from "../../actions/PaymentTerms";

export interface IPaymentTermsAutoProps {
    paymentTermsMode?: "submit" | "applepay" | "googlepay",
    customText?: string | JSX.Element,
    settings: ISettings;
    updateAccepted: (isAccepted: boolean) => void,
    intl: IInternationalization
}

class PaymentTermsAuto extends React.Component<IPaymentTermsAutoProps> {
    constructor(props: any) {
        super(props);
    }
    componentDidMount(): void {
        const { updateAccepted } = this.props;
        updateAccepted(true);
    }
    render() {
        const { settings , intl, customText, paymentTermsMode = "submit"} = this.props;
        const enPrefix = paymentTermsMode == "submit" ? ('By clicking "' + (customText ?? 'Submit Payment')+'"') :  
        paymentTermsMode == "applepay" ? "By using Apple Pay to submit your payment," :
        paymentTermsMode == "googlepay" ? "By using Google Pay to submit your payment," :
        ""
        return (
            <div>
                    <form>
                        <Row>
                            <Col xs={12}>
                                <div
                                    key={intl.locale}
                                    style={{
                                        border: "1px solid #e5e5e5",
                                        maxHeight: "200px",
                                        overflow: "auto",
                                        padding: "10px",
                                    }}
                                >
                                {enPrefix}{enPrefix ? ' y' : "Y"}ou agree to be bound by Heartland Payment System's <TermsOfUse/> and <PrivacyPolicy/> and <MerchantTermsAndConditions {...settings}/>.
                                </div>
                            </Col>
                        </Row>
                    </form>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    intl: state.intl
});

const mapDispatchToProps = {
    updateAccepted,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTermsAuto);