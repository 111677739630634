import {
    EDITCPCDATA_FAIL,
    EDITCPCDATA_LOAD,
    EDITCPCDATA_SUCCESS,
    ReceiptActions,
    RECEIPT_GENERATE,
    RECEIPT_LOAD,
    RECEIPT_LOAD_FAIL,
    RECEIPT_LOAD_SUCCESS,
    RECEIPT_SAVE,
} from "../actionTypes/Receipt";
import {
    editCPCDataRequest,
    generateReceiptPdf,
    getReceipt,
} from "../api/Receipt";
import {
    IApplication,
    IAppThunkAction,
    IBill,
    IBillType,
    ICpcDataRequest,
    IGeneratedReceiptResponse,
    IPayment,
    IPayor,
    IReceipt,
    ITransaction,
    IUser,
} from "../types";

export const loadReceipt =
    (
        transactionId: number,
        languageId: number
    ): IAppThunkAction<ReceiptActions> =>
    (dispatch) => {
        getReceipt(transactionId, languageId)
            .then((bills) =>
                dispatch({ type: RECEIPT_LOAD_SUCCESS, data: bills })
            )
            .catch((error) =>
                dispatch({ type: RECEIPT_LOAD_FAIL, data: error })
            );

        dispatch({ type: RECEIPT_LOAD });
    };

export const save =
    (
        billTypes: IBillType[],
        bills: IBill[],
        payor: IPayor,
        payment: IPayment,
        transaction: ITransaction,
        paymentTerms: string
    ): IAppThunkAction<ReceiptActions> =>
        (dispatch) => {
        dispatch({
            type: RECEIPT_SAVE,
            data: {
                billTypes,
                bills,
                payor,
                payment,
                transaction,
                paymentTerms,
            } as IReceipt,
        });
    };

export const generate =
    (
        application: IApplication,
        user: IUser,
        param: number,
        locale: string,
        onError: (text: string) => void,
        onSuccess: (result: IGeneratedReceiptResponse) => void
    ): IAppThunkAction<ReceiptActions> =>
    () => {
        generateReceiptPdf(application, user, param, locale)
            .then((result: IGeneratedReceiptResponse) => {
                if (result.isSuccessful) {
                    if (onSuccess) {
                        onSuccess(result);
                    }
                } else {
                    if (onError) {
                        onError(result.messages[0].messageDescription);
                    }
                }
            })
            .catch((error) => {
                if (onError && error instanceof Error) {
                    onError(error.message);
                }
            });
    };

export const refresh =
    (location: string): IAppThunkAction<ReceiptActions> =>
    (dispatch) => {
        dispatch({ type: RECEIPT_GENERATE, data: location });
    };

export const editCPCData =
    (
        application: IApplication,
        merchantName: string,
        languageId: number,
        request: ICpcDataRequest
    ): IAppThunkAction<ReceiptActions> =>
    (dispatch) => {
        editCPCDataRequest(application, merchantName, languageId, request)
            .then((response) =>
                dispatch({ type: EDITCPCDATA_SUCCESS, data: response })
            )
            .catch((error) =>
                dispatch({ type: EDITCPCDATA_FAIL, data: error })
            );

        dispatch({ type: EDITCPCDATA_LOAD });
    };
