import React from "react";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { loadTermsOfUse } from "../../../actions/Terms";
import { getLanguageCode } from "../../../utils/Intl";
import {
    LinkMerchantTermsAndConditions,
    ModalTitleMerchantTermsAndConditions,
    ButtonClose,
} from "../../../resources";
import { IApplicationState, IInternationalization, IPaymentTerms } from "../../../types";

export interface IMerchantTermsAndConditionsProps { loadTermsOfUse: typeof loadTermsOfUse, paymentTerms: IPaymentTerms, intl: IInternationalization, friendlyName: string }
export interface IMerchantTermsAndConditionsState { showModal: boolean }

class MerchantTermsAndConditions extends React.Component<IMerchantTermsAndConditionsProps, IMerchantTermsAndConditionsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
        };
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }

    close() {
        this.setState({ showModal: false });
    }

    open(event: any) {
        event.preventDefault();
        const { loadTermsOfUse, intl } = this.props;
        loadTermsOfUse(getLanguageCode(intl.locale));
        this.setState({ showModal: true });
    }

    createMarkup() {
        const { paymentTerms } = this.props;
        return { __html: paymentTerms.terms };
    }

    render() {
        return (
            <span>
                <a href="" onClick={this.open}>
                    <LinkMerchantTermsAndConditions {...this.props}/>
                </a>
                <Modal show={this.state.showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>{ModalTitleMerchantTermsAndConditions}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={this.createMarkup()} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.close}>{ButtonClose}</Button>
                    </Modal.Footer>
                </Modal>
            </span>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    intl: state.intl,
    paymentTerms: state.paymentTerms
});

const mapDispatchToProps = {
    loadTermsOfUse,
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantTermsAndConditions);
