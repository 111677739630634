import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import {
    Panel,
    Row,
    Col,
    Alert,
} from "react-bootstrap";
import FeeTable from "./FeeTable";
import GooglePayButton from "@google-pay/button-react";
import {
    IApplication,
    IPayment,
    ISettings,
} from "../../types";
import { IFormError } from "../../types/IFormError";
import PaymentTermsAuto from "../Verify/PaymentTermsAuto";

export interface GooglePayPanelProps {
    application: IApplication,
    merchant: ISettings,
    payment: IPayment,
    errors: IFormError,
    onError: (error: string, message: string) => void,
    hideFeePanel: boolean,
    allowedCardNetworks: google.payments.api.CardNetwork[], 
    billTotal: number
    handleWalletPaymentSubmitted: () => Promise<boolean>,
    setIsPaymentCompleted?: (isPaymentCompleted: boolean) => void;
}

class GooglePayPanel extends React.Component<GooglePayPanelProps, any> {

    public static defaultProps: Partial<any> = {
        hideFeePanel: false,
    };

    handlePayment = async (paymentData: google.payments.api.PaymentData): Promise<google.payments.api.PaymentAuthorizationResult> => {
        try {


            const { payment } = this.props;
            payment.firstName = paymentData.paymentMethodData.info?.billingAddress?.name ?? "";
            payment.address = (paymentData.paymentMethodData.info?.billingAddress?.address1 ?? "") +
                (paymentData.paymentMethodData.info?.billingAddress?.address2 ? " " + paymentData.paymentMethodData.info.billingAddress.address2 : "") +
                (paymentData.paymentMethodData.info?.billingAddress?.address3 ? " " + paymentData.paymentMethodData.info.billingAddress.address3 : "");
            payment.cardNumber = "xxxx xxxx xxxx " + paymentData.paymentMethodData.info?.cardDetails; //This is not a problem. It does not encrypt and include this in request, just gpay token
            payment.city = paymentData.paymentMethodData.info?.billingAddress?.locality ?? "";
            payment.country = paymentData.paymentMethodData.info?.billingAddress?.countryCode ?? "";
            payment.mobilePhone = paymentData.paymentMethodData.info?.billingAddress?.phoneNumber ?? "";
            payment.phone = paymentData.paymentMethodData.info?.billingAddress?.phoneNumber ?? "";
            payment.postal = paymentData.paymentMethodData.info?.billingAddress?.postalCode ?? "";
            payment.state = paymentData.paymentMethodData.info?.billingAddress?.administrativeArea ?? "";
            payment.googlePayToken = paymentData.paymentMethodData.tokenizationData.token;
            payment.type = paymentData.paymentMethodData.info?.cardNetwork ?? "";

            const isSuccessful = await this.props.handleWalletPaymentSubmitted();

            if (isSuccessful) {
                return { transactionState: "SUCCESS" };

            } else {
                this.props.onError("system", "Google Pay payment failed. Please try again.")
                return { transactionState: "ERROR" };
            }
        }
        catch (ex) {
            this.props.onError("system", "Google Pay payment failed. Please try again.")
            return { transactionState: "ERROR" };
        }
    };

    private getAppEnvironment = () => {
        const { merchant } = this.props;
        if (merchant.environment === 'Production') {
            return 'PRODUCTION';
        } else {
            return 'TEST';
        }
    }

    render() {
        const {
            merchant,
            payment,
            hideFeePanel,
            allowedCardNetworks,
            errors,
            billTotal,
        } = this.props;

        const props: any = Object.assign({}, this.props);
        const appEnvironment = this.getAppEnvironment();

        return (
            <Panel>
                <Panel.Body>
                    {(<Row>
                        {(
                            <Col style={{ textAlign: 'center' }} xs={12} sm={12} md={12}>
                            <GooglePayButton
                                environment={appEnvironment}
                                buttonColor="black"
                                buttonType="pay"
                                paymentRequest={{
                                    apiVersion: 2,
                                    apiVersionMinor: 0,
                                    allowedPaymentMethods: [
                                        {
                                            type: "CARD",
                                            parameters: {
                                                allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                                                allowedCardNetworks: allowedCardNetworks,
                                                billingAddressRequired: true,
                                                billingAddressParameters: {
                                                    format: "FULL",
                                                },
                                            },
                                            tokenizationSpecification: {
                                                type: "PAYMENT_GATEWAY",
                                                parameters: {
                                                    gateway: "globalpayments", 
                                                    gatewayMerchantId: merchant.heartlandMID,  
                                                },
                                            },
                                        },
                                    ],
                                    callbackIntents: ["PAYMENT_AUTHORIZATION"],
                                    merchantInfo: {
                                        merchantId: merchant.googlePayMerchantID, 
                                        merchantName: merchant.friendlyName, 
                                    },
                                    transactionInfo: {
                                        displayItems: [
                                            {
                                                label: "Amount",
                                                type: "SUBTOTAL",
                                                price: billTotal.toFixed(2),
                                            },
                                            {
                                                label: merchant.convenienceFeeLabel ?? "",
                                                type: "LINE_ITEM",
                                                price: (payment as IPayment).convenienceFee?.toFixed(2) ?? "0.00",
                                            },
                                        ],
                                        totalPriceStatus: "FINAL",
                                        totalPriceLabel: "Total",
                                        totalPrice: (payment as IPayment).total?.toFixed(2) ?? "0.00",
                                        currencyCode: "USD",
                                        countryCode: "US",
                                    },
                                }}

                                    onPaymentAuthorized={async (paymentData) => {
                                        return await this.handlePayment(paymentData);

                                    }
                                }
                                
                            />
                            </Col>
                        )}
                    </Row>)}
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            <br/>
                            <PaymentTermsAuto
                                paymentTermsMode={"googlepay"}
                                settings={this.props.merchant}
                            />
                            <br/>
                        </Col>
                    </Row>
                    {!hideFeePanel && (
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <br />
                                <FeeTable
                                    merchant={merchant}
                                    payment={payment}
                                    billTotal={billTotal}
                                />
                            </Col>
                        </Row> 
                    )}
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            {errors.system && (
                                <Alert bsStyle="danger">
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                    />{" "}
                                    {errors.system}
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </Panel.Body>
            </Panel>
        );
    }
}

export default GooglePayPanel;
