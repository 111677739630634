import React from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { HelpBlock } from "react-bootstrap";

export const LinkLogin = (
    <FormattedMessage id="link.login" defaultMessage="Log In" />
);
export const LinkPrivacyPolicy = (
    <FormattedMessage
        id="link.privacy_policy"
        defaultMessage="Privacy Policy"
    />
);
export const LinkTermsOfUse = (
    <FormattedMessage id="link.terms_of_use" defaultMessage="Terms of Use" />
);

export function LinkMerchantTermsAndConditions({ friendlyName }: { friendlyName:string}) { 
    return  (
      <FormattedMessage id="link.merchant_terms_and_conditions" defaultMessage="{friendlyName}'s Terms and Conditions" values={{ friendlyName }} />
    )
}
export const LinkStartOver = (
    <FormattedMessage
        id="link.start_over"
        defaultMessage="Click here to start over"
    />
);
export const LinkResetPassword = (
    <FormattedMessage
        id="link.forgot_password"
        defaultMessage="Reset your password"
    />
);

export const ModalTitlePrivacyPolicy = (
    <FormattedMessage
        id="modal.title.privacy_policy"
        defaultMessage="Privacy Policy"
    />
);
export const ModalTitleTermsOfUse = (
    <FormattedMessage
        id="modal.title.terms_of_use"
        defaultMessage="Terms of Use"
    />
);

export const ModalTitleMerchantTermsAndConditions= (
    <FormattedMessage
        id="modal.title.merchant_terms_and_conditions"
        defaultMessage="Merchant Terms and Conditions"
    />
);
export const ModalTitleSampleBill = (
    <FormattedMessage
        id="modal.title.sample_bill"
        defaultMessage="Sample Bill"
    />
);
export const ModalTitleBill = (
    <FormattedMessage id="modal.title.bill_image" defaultMessage="Bill" />
);
export const ModalTitleEmailReceipt = (
    <FormattedMessage
        id="modal.title.email_receipt"
        defaultMessage="Email Receipt"
    />
);

export const TabCreditCard = (
    <FormattedMessage id="tab.creditcard" defaultMessage="Credit Card" />
);
export const TabCreditCardSm = (
    <FormattedMessage id="tab.creditcard.sm" defaultMessage="Credit" />
);
export const TabDebitCard = (
    <FormattedMessage id="tab.debitcard" defaultMessage="Debit Card" />
);
export const TabDebitCardSm = (
    <FormattedMessage id="tab.debitcard.sm" defaultMessage="Debit" />
);
export const TooltipHeaderAmex = (
    <FormattedMessage
        id="amex.tooltip.header"
        defaultMessage="American Express"
    />
);
export const TooltipHeaderOther = (
    <FormattedMessage
        id="other.tooltip.header"
        defaultMessage="Visa/Mastercard/Discover"
    />
);

export const ButtonClose = (
    <FormattedMessage id="button.close" defaultMessage="Close" />
);
export const ButtonContinue = (
    <FormattedMessage id="button.continue" defaultMessage="Continue" />
);
export const ButtonContinueBillPage = (
    <FormattedMessage id="button.continue.bill" defaultMessage="Pay" />
);
export const ButtonContinuePayorPage = (
    <FormattedMessage
        id="button.continue.payor"
        defaultMessage="Continue - Enter Payment Account"
    />
);
export const ButtonContinuePaymentPage = (
    <FormattedMessage
        id="button.continue.payment"
        defaultMessage="Continue - Review Payment Information"
    />
);
export const ButtonEmail = (
    <FormattedMessage id="button.email" defaultMessage="Email" />
);
export const ButtonPrint = (
    <FormattedMessage id="button.print" defaultMessage="Print" />
);
export const ButtonCancelPayment = (
    <FormattedMessage
        id="button.cancel.payment"
        defaultMessage="Cancel Payment"
    />
);
export const ButtonCancel = (
    <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
);
export const ButtonBack = (
    <FormattedMessage id="button.back" defaultMessage="Back" />
);
export const ButtonReset = (
    <FormattedMessage id="button.reset" defaultMessage="Reset" />
);
export const ButtonSave = (
    <FormattedMessage id="button.save" defaultMessage="Save" />
);
export const ButtonSaveAndContinue = (
    <FormattedMessage
        id="button.save.continue"
        defaultMessage="Save and Continue"
    />
);
export const ButtonSaveAndClose = (
    <FormattedMessage id="button.save.close" defaultMessage="Save and Close" />
);
export const ButtonSend = (
    <FormattedMessage id="button.send" defaultMessage="Send" />
);
export const ButtonSearch = (
    <FormattedMessage id="button.search" defaultMessage="Search" />
);
export const ButtonSearchAgain = (
    <FormattedMessage id="button.searchagain" defaultMessage="Search Again" />
);
export const ButtonSkip = (
    <FormattedMessage id="button.skip" defaultMessage="Skip" />
);
export const ButtonSubmit = (
    <FormattedMessage id="button.submit" defaultMessage="Submit" />
);
export const ButtonOk = <FormattedMessage id="button.ok" defaultMessage="Ok" />;
export const ButtonYes = (
    <FormattedMessage id="button.yes" defaultMessage="Yes" />
);
export const ButtonNo = <FormattedMessage id="button.no" defaultMessage="No" />;
export const ButtonAddBill = (
    <FormattedMessage
        id="button.add_bill"
        defaultMessage="Enter a Bill to Pay"
    />
);
export const ButtonAddBills = (
    <FormattedMessage
        id="button.add_bills"
        defaultMessage="Add Another Bill to Pay"
    />
);
export const ButtonAddAllBills = (
    <FormattedMessage id="button.add_all_bills" defaultMessage="Add All" />
);
export const ButtonRemoveAllBills = (
    <FormattedMessage
        id="button.remove_all_bills"
        defaultMessage="Remove All"
    />
);

export const ButtonAutopaySetup = (
    <FormattedMessage
        id="button.autopay_setup"
        defaultMessage="Setup AutoPay"
    />
);
export const ButtonSubmitPayment = (
    <FormattedMessage
        id="button.submit_payment"
        defaultMessage="Submit Payment"
    />
);
export const ButtonSchedulePayment = (
    <FormattedMessage
        id="button.schedule_payment"
        defaultMessage="Schedule Payment"
    />
);
export const ButtonChangePaymentMethod = (
    <FormattedMessage
        id="button.change_payment"
        defaultMessage="Change Payment Method"
    />
);
export const LabelBillType = (
    <FormattedMessage id="label.billtypes" defaultMessage="Bill Types" />
);
export const LabelCustomAmount = (
    <FormattedMessage id="label.customamount" defaultMessage="Custom Amount" />
);
export const LabelNameOnAccount = (
    <FormattedMessage
        id="label.name_on_account"
        defaultMessage="Name on Account"
    />
);
export const LabelAccountType = (
    <FormattedMessage id="label.account_type" defaultMessage="Account Type" />
);
export const LabelRoutingNumber = (
    <FormattedMessage id="label.routing" defaultMessage="Routing Number" />
);
export const LabelAccountNumber = (
    <FormattedMessage id="label.account" defaultMessage="Account Number" />
);
export const LabelConfirmAccountNumber = (
    <FormattedMessage
        id="label.account.confirm"
        defaultMessage="Confirm Account Number"
    />
);
export const LabelSameProfileInfo = (
    <FormattedMessage
        id="label.sameasprofile"
        defaultMessage="Same as Profile Information"
    />
);
export const LabelSamePayorInfo = (
    <FormattedMessage
        id="label.sameaspayor"
        defaultMessage="Same as Payor Information"
    />
);
export const LabelBusinessName = (
    <FormattedMessage id="label.business" defaultMessage="Business Name" />
);
export const LabelFirstName = (
    <FormattedMessage id="label.firstname" defaultMessage="First Name" />
);
export const LabelMiddleName = (
    <FormattedMessage id="label.middlename" defaultMessage="Middle Name" />
);
export const LabelLastName = (
    <FormattedMessage id="label.lastname" defaultMessage="Last Name" />
);
export const LabelAddress = (
    <FormattedMessage id="label.address" defaultMessage="Address" />
);
export const LabelCountry = (
    <FormattedMessage id="label.country" defaultMessage="Country" />
);
export const LabelState = (
    <FormattedMessage id="label.state" defaultMessage="State" />
);
export const LabelStateProvince = (
    <FormattedMessage
        id="label.stateProvince"
        defaultMessage="State / Province"
    />
);
export const LabelCity = (
    <FormattedMessage id="label.city" defaultMessage="City" />
);
export const LabelPostal = (
    <FormattedMessage id="label.postal" defaultMessage="Postal Code" />
);
export const LabelCardNumber = (
    <FormattedMessage
        id="label.credit_card_number"
        defaultMessage="Card Number"
    />
);
export const LabelNameOnCard = (
    <FormattedMessage id="label.name_on_card" defaultMessage="Name on Card" />
);
export const LabelMonth = (
    <FormattedMessage id="label.month" defaultMessage="Month" />
);
export const LabelYear = (
    <FormattedMessage id="label.year" defaultMessage="Year" />
);
export const LabelCode = (
    <FormattedMessage id="label.code" defaultMessage="Security Code" />
);
export const LabelAmount = (
    <FormattedMessage id="label.amount" defaultMessage="Amount" />
);
export const LabelTotal = (
    <FormattedMessage id="label.total" defaultMessage="Total" />
);
export const LabelBaseAmount = (
    <FormattedMessage id="label.amountCharged" defaultMessage="Base Amount" />
);
export const LabelTotalAmount = (
    <FormattedMessage id="label.totalCharged" defaultMessage="Total Amount" />
);
export const LabelTransactionId = (
    <FormattedMessage
        id="label.transactionId"
        defaultMessage="Transaction Number"
    />
);
export const LabelPaymentDateTime = (
    <FormattedMessage
        id="label.paymentDateTime"
        defaultMessage="Payment Date"
    />
);
export const LabelEmail = (
    <FormattedMessage id="label.email" defaultMessage="Email" />
);
export const LabelReceiptEmail = (
    <FormattedMessage id="label.email_receipt" defaultMessage="Receipt Email" />
);
export const LabelConfirmEmail = (
    <FormattedMessage id="label.email_confirm" defaultMessage="Confirm Email" />
);
export const LabelPhoneNumber = (
    <FormattedMessage id="label.phone_number" defaultMessage="Phone Number" />
);
export const LabelPhoneCountry = (
    <FormattedMessage
        id="label.phone_number_country"
        defaultMessage="Phone Country"
    />
);
export const LabelMobilePhoneNumber = (
    <FormattedMessage
        id="label.mobile_phone_number"
        defaultMessage="Mobile Phone Number"
    />
);
export const LabelMobilePhoneCountry = (
    <FormattedMessage
        id="label.mobile_phone_number_country"
        defaultMessage="Mobile Phone Country"
    />
);
export function LabelBillInformation(information: any) {
    return (
        <FormattedMessage
            id="label.bill_information"
            defaultMessage="{information}"
            values={{ information }}
        />
    );
}
export const LabelSearch = (
    <FormattedMessage id="label.search" defaultMessage="Search" />
);
export const LabelPayorInformation = (
    <FormattedMessage id="label.payor_information" defaultMessage="Payor" />
);
export const LabelPaymentInformation = (
    <FormattedMessage id="label.payment_information" defaultMessage="Payment" />
);
export const LabelVerify = (
    <FormattedMessage id="label.verify" defaultMessage="Verify" />
);
export const LabelDone = (
    <FormattedMessage id="label.done" defaultMessage="Done" />
);
export function LabelFeeAmount(feeName: any) {
    return (
        <FormattedMessage
            id="label.feeCharged"
            defaultMessage="{feeName} Amount"
            values={{ feeName }}
        />
    );
}
export const LabelDownloadReceipt = (
    <FormattedMessage id="label.download_receipt" defaultMessage="Receipt" />
);
export const LabelAccountNickname = (
    <FormattedMessage
        id="label.account_nickname"
        defaultMessage="Account Nickname"
    />
);
export const LabelAccountActions = (
    <FormattedMessage
        id="label.account_actions"
        defaultMessage="Account Actions"
    />
);
export const LabelAccountIsDefault = (
    <FormattedMessage
        id="label.account.isDefault"
        defaultMessage="Default Account"
    />
);
export const LabelAccountExpiration = (
    <FormattedMessage
        id="label.account_expiration"
        defaultMessage="Expiration"
    />
);
export const LabelConfirmAutoPayDisable = (
    <FormattedMessage
        id="label.autopay_disable_confirm"
        defaultMessage="Confirm AutoPay Disable"
    />
);
export const LabelPreferredContactMethod = (
    <FormattedMessage
        id="label.preferred_contact_method"
        defaultMessage="Preferred Contact Method"
    />
);
export const LabelACHReturn = (
    <FormattedMessage
        id="label.ach.return"
        defaultMessage="Return Notification"
    />
);
export const LabelScheduledDate = (
    <FormattedMessage
        id="label.scheduled.date"
        defaultMessage="Scheduled Date"
    />
);
export const LabelPONumber = (
    <FormattedMessage id="label.ponumber" defaultMessage="PO Number" />
);
export const LabelTaxAmount = (
    <FormattedMessage id="label.taxamount" defaultMessage="Tax Amount" />
);
export const LabelIsTaxExempt = (
    <FormattedMessage id="label.istaxexempt" defaultMessage="Is Tax Exempt?" />
);
export const HeaderEditPaymentInformation = (
    <FormattedMessage
        id="header.edit_payment_information"
        defaultMessage="Edit Payment Information"
    />
);
export const HeaderPaymentInformation = (
    <FormattedMessage
        id="header.payment_information"
        defaultMessage="Payment Information"
    />
);
export const HeaderPaymentSucessfull = (
    <FormattedMessage
        id="header.payment_sucessfull"
        defaultMessage="Payment Successful"
    />
);
export const HeaderPaymentSubmitted = (
    <FormattedMessage
        id="header.payment_submitted"
        defaultMessage="Payment Submitted"
    />
);
export const HeaderEnterBillInformation = (
    <FormattedMessage
        id="header.billinfo.1"
        defaultMessage="Please enter your bill information"
    />
);
export const HeaderSelectBillInformation = (
    <FormattedMessage
        id="header.billinfo.2"
        defaultMessage="Please select a bill type to pay"
    />
);
export const HeaderBillsToPay = (
    <FormattedMessage id="header.bill_to_pay" defaultMessage="Bills to pay" />
);
export const HeaderCancel = (
    <FormattedMessage id="header.cancel" defaultMessage="Cancel Payment" />
);
export const HeaderCardHolder = (
    <FormattedMessage
        id="header.card_holder"
        defaultMessage="Card Holder Information"
    />
);
export const HeaderAccountHolder = (
    <FormattedMessage
        id="header.account_holder"
        defaultMessage="Account Holder Information"
    />
);
export const HeaderPayorInformation = (
    <FormattedMessage
        id="header.payor"
        defaultMessage="Please enter payor information."
    />
);
export const HeaderPersonalInformation = (
    <FormattedMessage
        id="header.personal_information"
        defaultMessage="Personal Information"
    />
);
export const HeaderAddressInformation = (
    <FormattedMessage
        id="header.address_information"
        defaultMessage="Address Information"
    />
);
export const HeaderContactInformation = (
    <FormattedMessage
        id="header.contact_information"
        defaultMessage="Contact Information"
    />
);
export const HeaderBillDetailInformation = (
    <FormattedMessage
        id="header.bill_detail_information"
        defaultMessage="Bill Detail Information"
    />
);
export const HeaderBillInformation = (
    <FormattedMessage
        id="header.bill_information"
        defaultMessage="Bill Information"
    />
);
export const HeaderReviewPage = (
    <FormattedMessage
        id="header.reveiw"
        defaultMessage="Review the information below, then click “Submit Payment”"
    />
);
export const HeaderNotice = (
    <FormattedMessage id="header.notice" defaultMessage="Notice" />
);
export const HeaderSessionTimeout = (
    <FormattedMessage
        id="header.session_timeout"
        defaultMessage="Session Expired"
    />
);
export const HeaderInvalidRequest = (
    <FormattedMessage
        id="header.invalid_request"
        defaultMessage="Invalid Request"
    />
);
export const HeaderPaymentCancelled = (
    <FormattedMessage
        id="header.payment_cancelled"
        defaultMessage="Payment Cancelled"
    />
);
export const HeaderResetPassword = (
    <FormattedMessage
        id="header.reset_password"
        defaultMessage="Reset MyAccount Password"
    />
);
export const HeaderTokenError = (
    <FormattedMessage id="header.token_error" defaultMessage="Token Error" />
);
export const HeaderLastPaidDate = (
    <FormattedMessage
        id="header.last_paid_date"
        defaultMessage="Last Paid Date"
    />
);

export const HeaderProfileInformation = (
    <FormattedMessage
        id="header.profile_information"
        defaultMessage="Profile Information"
    />
);
export const HeaderSaveProfile = (
    <FormattedMessage id="header.save_profile" defaultMessage="Save Profile" />
);
export const HeaderPasswordInformation = (
    <FormattedMessage
        id="header.password"
        defaultMessage="Password Information"
    />
);
export const HeaderDeleteAccount = (
    <FormattedMessage
        id="header.delete_account"
        defaultMessage="Delete Account"
    />
);
export const HeaderAccountsInformation = (
    <FormattedMessage
        id="header.accounts_information"
        defaultMessage="Payment Methods Saved in MyAccount Wallet"
    />
);
export const HeaderMyBills = (
    <FormattedMessage
        id="header.bills_information"
        defaultMessage="My Bills Ready for Payment"
    />
);
export const HeaderAutoPayBillNotifications = (
    <FormattedMessage
        id="header.autopay_billnotifications"
        defaultMessage="AutoPay and Bill Notification Settings"
    />
);
export const HeaderMyAccount = (
    <FormattedMessage
        id="header.myaccount_information"
        defaultMessage="My Account"
    />
);
export const HeaderAutoPay = (
    <FormattedMessage id="header.autopay" defaultMessage="AutoPay" />
);
export const HeaderBillType = (
    <FormattedMessage id="header.billtype" defaultMessage="Bill Type" />
);
export const HeaderNotifications = (
    <FormattedMessage
        id="header.notifications"
        defaultMessage="Notifications"
    />
);
export const HeaderSetupPassword = (
    <FormattedMessage
        id="header.setup_password"
        defaultMessage="Setup Password"
    />
);
export const MessageAgreeTerms = (
    <FormattedMessage
        id="message.agree_terms"
        defaultMessage="You must agree to the Terms and Conditions"
    />
);
export const MessageNoAccounts = (
    <FormattedMessage
        id="message.noaccounts"
        defaultMessage="No payment methods saved in MyAccount Wallet"
    />
);
export const MessageNoBills = (
    <FormattedMessage
        id="message.nobills"
        defaultMessage="No bills are saved to your account"
    />
);

export const MessageNoPaymentHistory = (
    <FormattedMessage
        id="message.nopaymenthistory"
        defaultMessage="No customer payments have been made."
    />
);
export const MessagePending = (
    <FormattedMessage id="message.pending" defaultMessage="pending" />
);
export const MessageCancelDialog = (
    <FormattedMessage
        id="message.cancel_dialog"
        defaultMessage="Are you sure you want to cancel this payment?"
    />
);
export const MessagePaymentFailed = (
    <FormattedMessage
        id="message.payment_failed"
        defaultMessage="Payment Failed"
    />
);
export const MessagePaymentProcessing = (
    <FormattedMessage
        id="message.payment_processing"
        defaultMessage="Your payment is processing, please do not resubmit or click the Back button."
    />
);
export const MessagePleasePrint = (
    <FormattedMessage
        id="message.please_print"
        defaultMessage="Please, print this page as your receipt and keep it for your records."
    />
);
export const MessageCaptchaFail = (
    <FormattedMessage
        id="message.captcha_fail"
        defaultMessage="This website uses Google ReCaptcha to prevent scripted payments. In order to make a payment on this website you will need to have access to www.recaptcha.net."
    />
);
export const MessageSessionExpired = (
    <FormattedMessage
        id="message.session_expired"
        defaultMessage="We are sorry, but your session has expired. Restart your payment process to try again..."
    />
);
export const MessageInvalidRequest = (
    <FormattedMessage
        id="message.invalid_request"
        defaultMessage="An invalid request was received."
    />
);
export const MessagePaymentCancelled = (
    <FormattedMessage
        id="message.payment_cancelled"
        defaultMessage="Your payment has been canceled, you will need to start over from the beginning."
    />
);
export const MessageRegisterFinishInstructions = (
    <FormattedMessage
        id="message.register_finish_instructions"
        defaultMessage="To finish registering your account, please enter your email address and we will send instructions on setting up your password."
    />
);
export const MessageForgotPasswordInstructions = (
    <FormattedMessage
        id="message.forgot_password_instructions"
        defaultMessage="Please enter your email address and we will send instructions on resetting your password."
    />
);
export const MessageResetEmailSent = (
    <FormattedMessage
        id="message.reset_email_sent"
        defaultMessage="If the information provided matches the data in our system, you will receive an email shortly with instructions on how to reset your password."
    />
);
export const MessagePasswordResetSuccess = (
    <FormattedMessage
        id="message.password_reset_success"
        defaultMessage="Your password has been reset."
    />
);
export const MessageTokenExpired = (
    <FormattedMessage
        id="message.token_expired"
        defaultMessage="Link Expired.This password reset link has expired.Please generate a new link and restart the process."
    />
);
export const MessageErrorProcessingRequest = (
    <FormattedMessage
        id="message.error_processing_request"
        defaultMessage="There was an error processing your request, please try again later."
    />
);
export const MessageDeleteAccount = (
    <FormattedMessage
        id="message.delete_account"
        defaultMessage="Payment Method was successfully deleted."
    />
);
export const MessageSaveAccount = (
    <FormattedMessage
        id="message.save_account"
        defaultMessage="Payment Method was successfully saved."
    />
);
export const MessageSaveProfile = (
    <FormattedMessage
        id="message.save_profile"
        defaultMessage="Profile was successfully updated."
    />
);
export const MessagePaidInFull = (
    <FormattedMessage id="message.paid_in_full" defaultMessage="Paid in full" />
);
export const MessageEnableAutopay = (
    <FormattedMessage
        id="message.enable_autopay"
        defaultMessage="AutoPay successfully enabled for this bill."
    />
);
export const MessageDisableAutopay = (
    <FormattedMessage
        id="message.disable_autopay"
        defaultMessage="AutoPay successfully disabled for this bill."
    />
);
export const MessageEnableAutopayNext = (
    <FormattedMessage
        id="message.enable_autopay_next"
        defaultMessage="AutoPay will not go into effect until next payment."
    />
);
export const MessageEnableNotification = (
    <FormattedMessage
        id="message.enable_notification"
        defaultMessage="Bill Notification successfully enabled for this bill."
    />
);
export const MessageDisableNotification = (
    <FormattedMessage
        id="message.disable_notification"
        defaultMessage="Bill Notification successfully disabled for this bill."
    />
);
export const MessageAutopayNavigate = (
    <FormattedMessage
        id="message.change_autopay"
        defaultMessage="Do you want to continue paying your bill manually?"
    />
);
export const MessagePasswordSetupSuccess = (
    <FormattedMessage
        id="message.password_setup_success"
        defaultMessage="Your password has been set."
    />
);
export const MessageAutopayDelete = (
    <FormattedMessage
        id="message.delete_autopay"
        defaultMessage="You must replace this payment method in AutoPay, would you like to proceed?"
    />
);
export const MessageRememberCard = (
    <FormattedMessage
        id="message.remember_card"
        defaultMessage="Remember this card for future payments."
    />
);
export const MessageScheduledPaymentFailed = (
    <FormattedMessage
        id="message.scheduled_payment_failed"
        defaultMessage="Schedule Payment Failed"
    />
);
export const MessageCpcEditSuccessful = (
    <FormattedMessage
        id="header.cpcedit_successful"
        defaultMessage="Purchase Order was successfully submitted."
    />
);
export const MessageCpcEditFailed = (
    <FormattedMessage
        id="header.cpcedit_failed"
        defaultMessage="Purchase Order was not successfully submitted."
    />
);
export const MessageEnterPONumber = (
    <FormattedMessage
        id="header.ponumber"
        defaultMessage="Would you like to provide a Purchase Order?"
    />
);
export function MessageEnterL2Data(cpcDescription: string) {
    return (
        <FormattedMessage
            id="header.l2data"
            defaultMessage="The host has identified this card as a {cpcDescription}."
            values={{ cpcDescription }}
        />
    );
}

export function MessageScheduledPaymentSuccess(merchantName: any) {
    return (
        <FormattedMessage
            id="message.scheduled_payment_success"
            defaultMessage="Your payment to {merchantName} has been scheduled."
            values={{ merchantName }}
        />
    );
}

export function MessageScheduledPaymentContact(merchantName: any) {
    return (
        <FormattedMessage
            id="message.scheduled_payment_contact"
            defaultMessage="If you have any questions or concerns about this scheduled payment, please contact {merchantName}."
            values={{ merchantName }}
        />
    );
}

export function MessageChangeAutopay(selectedId: any) {
    return (
        <FormattedMessage
            id="message.change_autopay"
            defaultMessage="Are you sure you want to disable AutoPay for {selectedId}?"
            values={{ selectedId }}
        />
    );
}

export function MessageDeleteAccountDialog(account: any) {
    return (
        <FormattedMessage
            id="message.delete_dialog"
            defaultMessage="Are you sure you want to delete {account}"
            values={{ account }}
        />
    );
}

export function MessageSaveProfileDialog(profile: any) {
    return (
        <FormattedMessage
            id="message.saveprofile_dialog"
            defaultMessage="Do you want to save changes to your profile {profile}?"
            values={{ profile }}
        />
    );
}

export function MessageCardInformation(type: any, last4: any) {
    return (
        <FormattedMessage
            id="message.card_information"
            defaultMessage="{type} {last4}."
            values={{ type, last4 }}
        />
    );
}
export function MessageEmailReceipt(email: any) {
    return (
        <FormattedMessage
            id="message.email_receipt"
            defaultMessage="A copy of the receipt was sent to {email}."
            values={{ email }}
        />
    );
}
export function MessageGreeting(buttonName: any) {
    return (
        <FormattedMessage
            id="message.welcome"
            defaultMessage="Click “{buttonName}” to begin the Payment Process."
            values={{ buttonName }}
        />
    );
}
export function MessagePhoneError(number: any) {
    return (
        <FormattedMessage
            id="message.phone_error"
            defaultMessage="This is not a valid phone number for the country provided. {number}"
            values={{ number }}
        />
    );
}
export const EmailPatternMismatch = (
    <HelpBlock>
        <FormattedMessage
            id="error.email_mismatch"
            defaultMessage="This is not a valid email address."
        />
    </HelpBlock>
);
export const PostalPatternMismatch = (
    <HelpBlock>
        <FormattedMessage
            id="error.postal_mismatch"
            defaultMessage="This is not a valid postal code. (Example 55555 or 55555-5555)"
        />
    </HelpBlock>
);
export function MessageConvenienceFee(feeName: any) {
    return (
        <FormattedMessage
            id="error.convenience_fee"
            defaultMessage="We are unable to calculate the {feeName} at this time."
            values={{ feeName }}
        />
    );
}
export const OptionSelectOne = (
    <FormattedMessage id="option.selectOne" defaultMessage="Select One" />
);
export const OptionJanuary = (
    <FormattedMessage id="option.january" defaultMessage="January" />
);
export const OptionFebruary = (
    <FormattedMessage id="option.february" defaultMessage="February" />
);
export const OptionMarch = (
    <FormattedMessage id="option.march" defaultMessage="March" />
);
export const OptionApril = (
    <FormattedMessage id="option.april" defaultMessage="April" />
);
export const OptionMay = (
    <FormattedMessage id="option.may" defaultMessage="May" />
);
export const OptionJune = (
    <FormattedMessage id="option.june" defaultMessage="June" />
);
export const OptionJuly = (
    <FormattedMessage id="option.july" defaultMessage="July" />
);
export const OptionAugust = (
    <FormattedMessage id="option.august" defaultMessage="August" />
);
export const OptionSeptember = (
    <FormattedMessage id="option.september" defaultMessage="September" />
);
export const OptionOctober = (
    <FormattedMessage id="option.october" defaultMessage="October" />
);
export const OptionNovember = (
    <FormattedMessage id="option.november" defaultMessage="November" />
);
export const OptionDecember = (
    <FormattedMessage id="option.december" defaultMessage="December" />
);
export const OptionPersonalChecking = (
    <FormattedMessage
        id="option.personalChecking"
        defaultMessage="Personal Checking"
    />
);
export const OptionBusinessChecking = (
    <FormattedMessage
        id="option.businessChecking"
        defaultMessage="Business Checking"
    />
);
export const OptionPersonalSavings = (
    <FormattedMessage
        id="option.personalSavings"
        defaultMessage="Personal Savings"
    />
);
export const OptionBusinessSavings = (
    <FormattedMessage
        id="option.businessSavings"
        defaultMessage="Business Savings"
    />
);
export const TextToken = (
    <FormattedMessage id="text.token" defaultMessage="Token" />
);
export function ButtonSaveCustom(custom: any) {
    return (
        <FormattedMessage
            id="button.save_custom"
            defaultMessage="Save {custom}"
            values={{ custom }}
        />
    );
}
export function MessageSuccessCustom(custom: any) {
    return (
        <FormattedMessage
            id="message.success_custom"
            defaultMessage="{custom} created successfully!"
            values={{ custom }}
        />
    );
}
export const HeaderSuccess = (
    <FormattedMessage id="header.success" defaultMessage="Success" />
);
export const ErrorOverlimit = (
    <FormattedMessage
        id="error.overlimit"
        defaultMessage="The value can not be greater than {amount}."
    />
);
export const ErrorUnderlimit = (
    <FormattedMessage
        id="error.underlimit"
        defaultMessage="The value can not be less than {amount}."
    />
);
export function ErrorSumOverlimit(amount: any) {
    return (
        <FormattedMessage
            id="error.sumoverlimit"
            defaultMessage="The sum of the bill amounts can not be greater than {amount}."
            values={{ amount }}
        />
    );
}
export function HeaderSearchModal(first: any, last: any, totalRowCount: any) {
    return (
        <FormattedMessage
            id="header.search.modal"
            defaultMessage="Showing results {first} - {last} of {totalRowCount}"
            values={{ first, last, totalRowCount }}
        />
    );
}
export const ButtonAdd = (
    <FormattedMessage id="button.add" defaultMessage="Add" />
);
export const ButtonRemove = (
    <FormattedMessage id="button.remove" defaultMessage="Remove" />
);
export const ButtonPaid = (
    <FormattedMessage id="button.paid" defaultMessage="Paid" />
);
export const LabelConfigError = (
    <FormattedMessage
        id="label.config.error"
        defaultMessage="Configuration Error"
    />
);
export const MessageConfigError = (
    <FormattedMessage
        id="message.config.error"
        defaultMessage="Bill Type does have any searchable Ids. Please contact support."
    />
);
export const MessageACHReturn = (
    <FormattedMessage
        id="message.ach.return"
        defaultMessage="Please email me at the address provided above if my eCheck payment returns."
    />
);
export const LabelDueDate = (
    <FormattedMessage id="label.duedate" defaultMessage="Due Date" />
);
export const LabelOvermaxPaymentAmount = (
    <FormattedMessage id="label.over.maxpayment" defaultMessage="Over Limit" />
);
export const LabelViewBillInfoHeader = (
    <FormattedHTMLMessage
        id="label.viewbill.info.header"
        defaultMessage="View Bill"
    />
);
export const LabelViewBillInfoBody = (
    <FormattedHTMLMessage
        id="label.viewbill.info.body"
        defaultMessage="This is a copy of the tax bill for your records./nPlease do not print and mail your tax bill copy with a payment./nPay your tax bill online. It's fast, free and easy!"
    />
);
export const HeaderSearchForBillsToPay = (
    <FormattedMessage
        id="header.search_for_bills"
        defaultMessage="Search for BIlls"
    />
);
export const HeaderExitToPayment = (
    <FormattedMessage
        id="header.exit_to_payment"
        defaultMessage="Return to Payment"
    />
);
export const MessageAnd = (
    <FormattedMessage id="message.and" defaultMessage="and" />
);
export function MessageSelectBillType(allowMultiple: any) {
    let bills = 1;
    if (allowMultiple) {
        bills = 2;
    }
    return (
        <FormattedMessage
            id="message.select_billtype"
            defaultMessage="Let's find the {bills, plural, one {bill} other {bills}} you wish to pay."
            values={{ bills }}
        />
    );
}
export function MessageStep2(billType: any, and: any, nbrBillTypes: number) {
    const a = [];
    if (billType.id1.label && billType.id1.isSearchable) {
        a.push(billType.id1.label);
    }
    if (billType.id2.label && billType.id2.isSearchable) {
        a.push(billType.id2.label);
    }
    if (billType.id3.label && billType.id3.isSearchable) {
        a.push(billType.id3.label);
    }
    if (billType.id4.label && billType.id4.isSearchable) {
        a.push(billType.id4.label);
    }
    return (
        <FormattedMessage
            id={nbrBillTypes > 1 ? "message.step2" : "no-text"}
            defaultMessage="Search by {ids}."
            values={{
                ids: [a.slice(0, -1).join(", "), a.slice(-1)[0]].join(
                    a.length < 2 ? "" : " " + and + " "
                ),
            }}
        />
    );
}
export const MessageSearchBy = (
    <FormattedMessage
        id="message.searchby"
        defaultMessage="Enter your values and then click Search."
    />
);
export const MessageStep1 = (
    <FormattedMessage
        id="message.step1"
        defaultMessage="Step 1 - Select a bill type."
    />
);
export const MessageSearchAgain = (
    <FormattedMessage
        id="message.searchagain"
        defaultMessage="Keep in mind, that you may return to the search to find more bills to pay in one transaction."
    />
);
export const MessageSingleBillPay1 = (
    <FormattedMessage
        id="message.singlebillpay1"
        defaultMessage="This merchant only allows one bill to be paid per transaction."
    />
);
export const MessageSingleBillPay2 = (
    <FormattedMessage
        id="message.singlebillpay2"
        defaultMessage="To pay the bill you already selected, click 'Pay'."
    />
);
export const MessageSingleBillPay3 = (
    <FormattedMessage
        id="message.singlebillpay3"
        defaultMessage="To start over and select a different bill, click 'Cancel Payment'."
    />
);
export const LabelMerchantName = (
    <FormattedMessage id="label.merchantname" defaultMessage="Merchant Name" />
);
export const LabelAmountDue = (
    <FormattedMessage id="label.amountdue" defaultMessage="Amount To Pay" />
);
export const LabelUnderMinPaymentAmount = (
    <FormattedMessage
        id="label.under.minpayment"
        defaultMessage="Under Minimum"
    />
);
export function ErrorSumUnderlimit(amount: any) {
    return (
        <FormattedMessage
            id="error.sumundermin"
            defaultMessage="The total amount cannot be less than {amount}."
            values={{ amount }}
        />
    );
}
export const HeaderIdleTimeout = (
    <FormattedMessage
        id="header.idletimeout"
        defaultMessage="Your session is about to expire."
    />
);
export function MessageIdleTimeout1(counter: any) {
    return (
        <FormattedMessage
            id="message.idletimeout1"
            defaultMessage="Your session will end in {counter} seconds."
            values={{ counter }}
        />
    );
}
export const MessageIdleTimeout2 = (
    <FormattedMessage
        id="message.idletimeout2"
        defaultMessage="Do you want to continue your session?"
    />
);
export const SignIn = (
    <FormattedMessage id="message.signin" defaultMessage="SignIn" />
);
export const Register = (
    <FormattedMessage id="message.register" defaultMessage="Register" />
);
export const RegisterFinish = (
    <FormattedMessage
        id="message.register.finish"
        defaultMessage="Finish Registration"
    />
);
export const ContinueAsGuest = (
    <FormattedMessage
        id="message.continueAsGuest"
        defaultMessage="Continue As Guest"
    />
);
export const ManageProfile = (
    <FormattedMessage
        id="message.manageProfile"
        defaultMessage="Manage Profile"
    />
);
export const ManageFactors = (
    <FormattedMessage
        id="message.manageFactors"
        defaultMessage="Manage Multifactor Authentication"
    />
);
export const ManageAccounts = (
    <FormattedMessage
        id="message.manageAccounts"
        defaultMessage="Manage Accounts"
    />
);
export const ManageAutoPay = (
    <FormattedMessage
        id="message.manageAutoPay"
        defaultMessage="Manage AutoPay"
    />
);
export const MyBills = (
    <FormattedMessage id="message.myBills" defaultMessage="My Bills" />
);
export const PaymentHistory = (
    <FormattedMessage
        id="message.paymentHistory"
        defaultMessage="Payment History"
    />
);
export const PaymentHistoryInfo = (
    <FormattedMessage
        id="message.paymenthistory_info"
        defaultMessage="Customer Payment History"
    />
);
export const ChangePassword = (
    <FormattedMessage
        id="message.changePassword"
        defaultMessage="Change Password"
    />
);
export const Logout = (
    <FormattedMessage id="message.logout" defaultMessage="Logout" />
);
export const Password = (
    <FormattedMessage id="message.password" defaultMessage="Password" />
);
export const CurrentPassword = (
    <FormattedMessage
        id="message.currentPassword"
        defaultMessage="Current Password"
    />
);
export const NewPassword = (
    <FormattedMessage id="message.newPassword" defaultMessage="New Password" />
);
export const RetypePassword = (
    <FormattedMessage
        id="message.retypePassword"
        defaultMessage="Re-type your password"
    />
);
export const PasswordLength = (
    <FormattedMessage
        id="message.passwordLength"
        defaultMessage="Passwords must be at least 9 characters long."
    />
);
export const PasswordLower = (
    <FormattedMessage
        id="message.passwordLower"
        defaultMessage="Passwords must contain at least one lowercase letter."
    />
);
export const PasswordUpper = (
    <FormattedMessage
        id="message.passwordUpper"
        defaultMessage="Passwords must contain at least one uppercase letter."
    />
);
export const PasswordNumber = (
    <FormattedMessage
        id="message.passwordNumber"
        defaultMessage="Passwords must contain at least one number."
    />
);
export const PasswordSpecial = (
    <FormattedMessage
        id="message.passwordSpecial"
        defaultMessage="Passwords must contain at least one special character."
    />
);
export const PasswordWhiteSpace = (
    <FormattedMessage
        id="message.passwordWhiteSpace"
        defaultMessage="Passwords must not contain any spaces."
    />
);
export const PasswordMatch = (
    <FormattedMessage
        id="message.passwordMatch"
        defaultMessage="Both passwords must match."
    />
);
export const AjaxErrorMessage = (
    <FormattedMessage
        id="message.ajaxErrorMessage"
        defaultMessage="There was an error processing your request, please try again later."
    />
);
export const MyAccounts = (
    <FormattedMessage id="message.myAccounts" defaultMessage="My Accounts" />
);
export function MessageStatesToDeny(merchantName: any, states: any) {
    if (states.length === 1) {
        return (
            <FormattedMessage
                id="message.statesToDeny1"
                defaultMessage="{merchantName} does not accept payments from {state}."
                values={{ merchantName, state: states[0] }}
            />
        );
    } else if (states.length === 2) {
        return (
            <FormattedMessage
                id="message.statesToDeny2"
                defaultMessage="{merchantName} does not accept payments from {firstState} or {secondState}."
                values={{
                    merchantName,
                    firstState: states[0],
                    secondState: states[1],
                }}
            />
        );
    }
    return (
        <FormattedMessage
            id="message.statesToDeny3"
            defaultMessage="{merchantName} does not accept payments from {states}, or {lastState}."
            values={{
                merchantName,
                states: states.slice(0, -1).join(", "),
                lastState: states.slice(-1),
            }}
        />
    );
}
export const HeaderCancelToken = (
    <FormattedMessage id="header.cancelToken" defaultMessage="Cancel" />
);
export const MessageCancelTokenDialog = (
    <FormattedMessage
        id="message.cancelToken"
        defaultMessage="Are you sure you want to cancel this action?"
    />
);
export const HeaderAccountInformation = (
    <FormattedMessage
        id="header.accountInformation"
        defaultMessage="Account Information"
    />
);
export const ErrorPasswordStrength = (
    <FormattedMessage
        id="error.passwordStrength"
        defaultMessage="Passwords must be at least 9 characters and contain at least one lowercase letter, one uppercase letter, one number, and one special character. Spaces are not allowed."
    />
);
export const BankInformationHeader = (
    <FormattedMessage
        id="header.bankInformation"
        defaultMessage="Bank Information"
    />
);
export const MessageMultiFactorChallengeInstructions = (
    <FormattedMessage
        id="message.multifactorchallenge_instructions"
        defaultMessage="Multifactor Authentication increases security. Please select the factor you would like to use to authenticate."
    />
);

export const MessageMultiFactorEnrollmentInstructions = (
    <FormattedMessage
        id="message.multifactorenrollment_instructions"
        defaultMessage="Multifactor Authentication increases security. Please select one or more factors you would like to use to authenticate going forward."
    />
);

export const MessageMultiFactorManagementInstructions = (
    <FormattedMessage
        id="message.multifactormanagement_instructions"
        defaultMessage="Use the buttons below to enroll or unenroll from any of the authentication factors we offer. Please note that you must enroll and fully activate an authentication factor before being able to log into My Account."
    />
);

export const MessageMFAEnterCodeLabel = (
    <FormattedMessage
        id="message.mfaentercode"
        defaultMessage="Please enter the code you receive. Please wait at least 30 seconds before attempting to resend the code if you do not receive it."
    />
);

export const MessageMFAEnterCodeTotpLabel = (
    <FormattedMessage
        id="message.mfaentercodetotp"
        defaultMessage="To configure, open an authenticator app and scan the QR code or enter the code manually. After an authenticator app has been configured, enter the generated code into the text box below."
    />
);

export const MFACodeLabel = (
    <FormattedMessage id="message.mfacode" defaultMessage="Enter Code" />
);

export const MessageSMSEnrollment = (
    <FormattedMessage
        id="message.smsenrollment"
        defaultMessage="Please enter the mobile phone number you would like to use for text message verification."
    />
);

export const MessageCallEnrollment = (
    <FormattedMessage
        id="message.callenrollment"
        defaultMessage="Please enter the phone number, and extension if necessary, you would like to use for phone call message verification."
    />
);

export const PhoneExtensionLabel = (
    <FormattedMessage id="label.phoneextension" defaultMessage="Extension" />
);

export const RememberDeviceLabel = (
    <FormattedMessage
        id="label.rememberdevice"
        defaultMessage="Remember this device"
    />
);

export const UnenrollFactorDialogHeader = (
    <FormattedMessage
        id="label.unenrollfactorheader"
        defaultMessage="Unenroll from factor"
    />
);

export const UnenrollFactorDialogMessage = (
    <FormattedMessage
        id="label.unenrollfactormessage"
        defaultMessage="Are you sure you would to unenroll from this factor?"
    />
);

export const MessageMultiFactorEnrollmentInstructionsNewUser = (
    <FormattedMessage
        id="message.multifactorenrollment_instructions_newuser"
        defaultMessage="Your user account has been created. Please select one or more factors you would like to use to authenticate going forward."
    />
);

export const MessagePaymentHasBeenCompleted = (
    <FormattedMessage
        id="message.payment_completed"
        defaultMessage="Payment has been completed. Please click the Finish button on the Done step to return to the merchants website."
    />
);

export const ButtonResend = (
    <FormattedMessage id="button.resend" defaultMessage="Resend" />
);

export const BillSearchDisclaimerParagraph1 = (
    <FormattedHTMLMessage id="disclaimer.paragraph1" />
);
export const BillSearchDisclaimerParagraph2a = (
    <FormattedHTMLMessage id="disclaimer.paragraph2a" />
);
export const BillSearchDisclaimerParagraph2b = (
    <FormattedHTMLMessage id="disclaimer.paragraph2b" />
);
export const BillSearchDisclaimerParagraph3 = (
    <FormattedHTMLMessage id="disclaimer.paragraph3" />
);
export const BillSearchDisclaimerParagraph4 = (
    <FormattedHTMLMessage id="disclaimer.paragraph4" />
);
export const BillSearchArticle1 = (
    <FormattedHTMLMessage id="billSearch.article1" />
);
export const BillSearchArticle2 = (
    <FormattedHTMLMessage id="billSearch.article2" />
);
export const BillSearchSearch = (
    <FormattedMessage id="billSearch.search" defaultMessage="Search" />
);
export const BillSearchSearchTitle = (
    <FormattedMessage
        id="billSearch.search_title"
        defaultMessage="Search Property Tax Information"
    />
);
export const BillSearchResults = (
    <FormattedMessage
        id="billSearch.search_results"
        defaultMessage="Search Results"
    />
);
export const BillSearchResultsTitle = (
    <FormattedMessage
        id="billSearch.search_results_title"
        defaultMessage="Property Tax Information Search Results"
    />
);
export const BillSearchShoppingCart = (
    <FormattedMessage
        id="billSearch.shopping_cart"
        defaultMessage="Shopping Cart"
    />
);
export const BillSearchShoppingCartTitle = (
    <FormattedMessage
        id="billSearch.shopping_cart_title"
        defaultMessage="Property Tax Information Shopping Cart"
    />
);
export const BillSearchPayorInformation = (
    <FormattedMessage
        id="billSearch.payor_information"
        defaultMessage="Payor Information"
    />
);
export const BillSearchPayorInformationTitle = (
    <FormattedMessage
        id="billSearch.payor_information_title"
        defaultMessage="Payor Information"
    />
);
export const BillSearchPaymentInformation = (
    <FormattedMessage
        id="billSearch.payment_information"
        defaultMessage="Payment Information"
    />
);
export const BillSearchPaymentInformationTitle = (
    <FormattedMessage
        id="billSearch.payment_information_title"
        defaultMessage="Payment Information"
    />
);
export const BillSearchVerification = (
    <FormattedMessage
        id="billSearch.verification"
        defaultMessage="Verification"
    />
);
export const BillSearchVerificationTitle = (
    <FormattedMessage
        id="billSearch.verification_title"
        defaultMessage="Verification"
    />
);

export const ReceiptPageTitle = (
    <FormattedMessage id="billSearch.Receipt_Page_Header_Text"></FormattedMessage>
);
export const ReceiptPageMainTitle = (
    <FormattedMessage id="billSearch.Receipt_Page_Header_Title"></FormattedMessage>
);
export const BillSearchReceipt = (
    <FormattedMessage id="billSearch.receipt" defaultMessage="Receipt" />
);
export const BillSearchReceiptTitle = (
    <FormattedMessage id="billSearch.receipt_title" defaultMessage="Receipt" />
);
export const BillSearchMainTitle = (
    <FormattedMessage
        id="billSearch.main_title"
        defaultMessage="Search Property Tax Information"
    />
);
export const BillSearchBillNumber = (
    <FormattedMessage id="billSearch.bill_number.option_title" />
);
export const BillSearchTitle = (
    <FormattedMessage
        id="billSearch.title"
        defaultMessage="Search by one of the following 3 options"
    />
);
export const BillSearchOptionBtn = (
    <FormattedMessage
        id="billSearch.option.btn"
        defaultMessage="Begin Search"
    />
);
export const BillSearchOption1 = (
    <FormattedMessage
        id="billSearch.option1"
        defaultMessage="Option 1: Assessor Parcel Number, Supplemental Bill Number, or Escape Bill Number"
    />
);
export const BillSearchOption1Number = (
    <FormattedMessage
        id="billSearch.option1.number"
        defaultMessage="Bill Number"
    />
);
export const BillSearchOption1NumberHelp = (
    <FormattedMessage
        id="billSearch.option1.number_help"
        defaultMessage="(10 digit number without dashes or spaces)"
    />
);
export const BillSearchOption2 = (
    <FormattedMessage
        id="billSearch.option2"
        defaultMessage="Option 2: Mailing Address"
    />
);
export const BillSearchOption2MailField = (
    <FormattedMessage
        id="billSearch.option2.mail_field"
        defaultMessage="Mailing Address"
    />
);
export const BillSearchOption2MailFieldHelp = (
    <FormattedMessage
        id="billSearch.option2.mail_field_help"
        defaultMessage="Example: 123 Main (st, ct, rd, dr, city not required)"
    />
);
export const BillSearchOption2countryField = (
    <FormattedMessage
        id="billSearch.option2.country_field"
        defaultMessage="Select Country"
    />
);
export const BillSearchOption2ZipCode = (
    <FormattedMessage
        id="billSearch.option2.zip_code"
        defaultMessage="Zip Code"
    />
);
export const BillSearchOption2ZipCodeHelp = (
    <FormattedMessage
        id="billSearch.option2.zip_code_help"
        defaultMessage="Example: 12345 or 12345-1234"
    />
);
export const BillSearchOption3 = (
    <FormattedMessage
        id="billSearch.option3"
        defaultMessage="Option 3: Unsecured Bill Number"
    />
);
export const BillSearchOption3YearField = (
    <FormattedMessage
        id="billSearch.option3.year_field"
        defaultMessage="Enter Tax Year (4 digits)"
    />
);
export const BillSearchOption3UnsecuredBillNumberField = (
    <FormattedMessage
        id="billSearch.option3.unsecured_bill_number_field"
        defaultMessage="Enter Bill Number (6 digits)"
    />
);
export const BillSearchNewSession = (
    <FormattedMessage
        id="billSearch.new_session"
        defaultMessage="New Session"
    />
);
export const BillSearchResultArticle1 = (
    <FormattedHTMLMessage id="billSearchResult.article1" />
);
export const BillSearchResultContentArticle = (
    <FormattedHTMLMessage id="billSearchResultContent.article" />
);
export const BillSearchResultMainTitle = (
    <FormattedMessage
        id="billSearchResult.main_title"
        defaultMessage="Property Tax Information Search Results"
    />
);
export const BillSearchResultBtn = (
    <FormattedMessage id="billSearchResult.btn" defaultMessage="New Search" />
);
export const BillSearchResultParcelNumber = (
    <FormattedMessage
        id="billSearchResult.header.parcel_number"
        defaultMessage="Parcel Number"
    />
);
export const BillSearchResultOwner = (
    <FormattedMessage
        id="billSearchResult.header.owner"
        defaultMessage="Owner"
    />
);
export const BillSearchResultInst = (
    <FormattedMessage
        id="billSearchResult.header.instalment_type"
        defaultMessage="Inst"
    />
);
export const BillSearchResultPaymentType = (
    <FormattedMessage
        id="billSearchResult.header.payment_type"
        defaultMessage="Payment Type"
    />
);
export const BillSearchResultInstlmntAmount = (
    <FormattedMessage
        id="billSearchResult.header.installment_amount"
        defaultMessage="Installment Amount"
    />
);
export const BillSearchResultDelinquent = (
    <FormattedMessage
        id="billSearchResult.header.delinquent_after"
        defaultMessage="Delinquent After"
    />
);
export const BillSearchResultLate = (
    <FormattedMessage
        id="billSearchResult.header.late_after"
        defaultMessage="Late After"
    />
);
export const BillSearchResultStatus = (
    <FormattedMessage
        id="billSearchResult.header.status"
        defaultMessage="Status"
    />
);
export const BillSearchResultAmountDue = (
    <FormattedMessage
        id="billSearchResult.header.amount_due"
        defaultMessage="Amount Due"
    />
);
export const BillSearchResultTotalDue = (
    <FormattedMessage
        id="billSearchResult.header.total_due"
        defaultMessage="Total Due"
    />
);
export const BillSearchResultAction = (
    <FormattedMessage
        id="billSearchResult.header.action"
        defaultMessage="Action"
    />
);
export const BillSearchResultBillNumber = (
    <FormattedMessage
        id="billSearchResult.header.bill_number"
        defaultMessage="Bill Number"
    />
);
export const BillSearchResultDefaulted = (
    <FormattedMessage
        id="billSearchResult.title.defaulted"
        defaultMessage="Defaulted"
    />
);
export const BillSearchResultSecured = (
    <FormattedMessage
        id="billSearchResult.title.secured"
        defaultMessage="Secured"
    />
);
export const BillSearchResultUnsecured = (
    <FormattedMessage
        id="billSearchResult.title.unsecured"
        defaultMessage="Unsecured"
    />
);
export const BillSearchResultSupplemental = (
    <FormattedMessage
        id="billSearchResult.title.supplemental"
        defaultMessage="Supplemental"
    />
);
export const BillSearchResultCellInstl1 = (
    <FormattedMessage
        id="billSearchResult.cell.installment1"
        defaultMessage="1st Installment"
    />
);
export const BillSearchResultCellInstl2 = (
    <FormattedMessage
        id="billSearchResult.cell.installment2"
        defaultMessage="2nd Installment"
    />
);
export const BillSearchResultCellInstl1Mobile = (
    <FormattedMessage
        id="billSearchResult.cell.installment1_mobile"
        defaultMessage="1st"
    />
);
export const BillSearchResultCellInstl2Mobile = (
    <FormattedMessage
        id="billSearchResult.cell.installment2_mobile"
        defaultMessage="2nd"
    />
);
export const BillSearchResultBtnViewBill = (
    <FormattedMessage
        id="billSearchResult.btn.view_bill"
        defaultMessage="New Session"
    />
);
export const BillSearchResultBtnAddCart = (
    <FormattedMessage
        id="billSearchResult.btn.add_cart"
        defaultMessage="Add to Cart"
    />
);
export const BillSearchResultBtnAddCartMobile = (
    <FormattedMessage
        id="billSearchResult.btn.add_cart_mobile"
        defaultMessage="Add"
    />
);
export const BillSearchResultBtnAddC1 = (
    <FormattedMessage
        id="billSearchResult.btn.add_cart_1"
        defaultMessage="Add 1st to Cart"
    />
);
export const BillSearchResultBtnAddC1Mobile = (
    <FormattedMessage
        id="billSearchResult.btn.add_cart_1_mobile"
        defaultMessage="1st"
    />
);
export const BillSearchResultBtnAddC2 = (
    <FormattedMessage
        id="billSearchResult.btn.add_cart_2"
        defaultMessage="Add 2nd to Cart"
    />
);
export const BillSearchResultBtnAddC2Mobile = (
    <FormattedMessage
        id="billSearchResult.btn.add_cart_2_mobile"
        defaultMessage="2nd"
    />
);
export const BillSearchResultBtnCBoth = (
    <FormattedMessage
        id="billSearchResult.btn.add_cart_both"
        defaultMessage="Add Both to Cart"
    />
);
export const BillSearchResultBtnCBothMobile = (
    <FormattedMessage
        id="billSearchResult.btn.add_cart_both_mobile"
        defaultMessage="Both"
    />
);
export const BillSearchResultBtnInCart = (
    <FormattedMessage
        id="billSearchResult.btn.in_cart"
        defaultMessage="In Cart"
    />
);
export const BillSearchCartHeaderTitle = (
    <FormattedMessage id="billSearch.Cart.header.title" />
);
export const BillSearchCartHeaderPNumber = (
    <FormattedMessage id="billSearch.Cart.header.parcel_bill_number" />
);
export const BillSearchCartHeaderType = (
    <FormattedMessage id="billSearch.Cart.header.type" />
);
export const BillSearchCartHeaderPaymentType = (
    <FormattedMessage id="billSearch.Cart.header.payment_type" />
);
export const BillSearchCartHeaderAmount = (
    <FormattedMessage id="billSearch.Cart.header.amount" />
);
export const BillSearchCartFooterTotal = (
    <FormattedMessage id="billSearch.Cart.footer.cart_total" />
);
export const BillSearchCartBtnRemove = (
    <FormattedMessage id="billSearch.Cart.btn.remove" />
);
export const BillSearchCartBtnRemoveMobile = (
    <FormattedMessage id="billSearch.Cart.btn.remove_mobile" />
);
export const BillSearchCartBtnCheckOut = (
    <FormattedMessage id="billSearch.Cart.btn.check_out" />
);
export const BillSearchCartBtnClear = (
    <FormattedMessage id="billSearch.Cart.btn.clear_cart" />
);
export const BillSearchCartBtnSResult = (
    <FormattedMessage id="billSearch.Cart.btn.search_result" />
);
export const BillSearchCartBtnSearch = (
    <FormattedMessage id="billSearch.Cart.btn.new_search" />
);
export const BillSearchCartArticle = (
    <FormattedHTMLMessage id="billSearch.Cart.article" />
);
export const BillSearchNavbarCart = (
    <FormattedMessage id="billSearch.Navbar.cart" />
);
export const BillSearchNavbarDisclaimer = (
    <FormattedMessage id="billSearch.Navbar.disclaimer" />
);
export const BillSearchNavbarContactInfo = (
    <FormattedMessage id="billSearch.Navbar.contact_info" />
);

export const BillSearchMobileStepPosition1 = (
    <FormattedMessage
        id="billSearch.MobileStepPosition.1"
        defaultMessage="Step 1 of 7 - Search"
    />
);
export const BillSearchMobileStepPosition2 = (
    <FormattedMessage
        id="billSearch.MobileStepPosition.2"
        defaultMessage="Step 2 of 7 - Search Result"
    />
);
export const BillSearchMobileStepPosition3 = (
    <FormattedMessage
        id="billSearch.MobileStepPosition.3"
        defaultMessage="Step 3 of 7 - Shopping Cart"
    />
);
export const BillSearchMobileStepPosition4 = (
    <FormattedMessage
        id="billSearch.MobileStepPosition.4"
        defaultMessage="Step 4 of 7 - Payor"
    />
);
export const BillSearchMobileStepPosition5 = (
    <FormattedMessage
        id="billSearch.MobileStepPosition.5"
        defaultMessage="Step 5 of 7 - Payment"
    />
);
export const BillSearchMobileStepPosition6 = (
    <FormattedMessage
        id="billSearch.MobileStepPosition.6"
        defaultMessage="Step 6 of 7 - Verify"
    />
);
export const BillSearchMobileStepPosition7 = (
    <FormattedMessage
        id="billSearch.MobileStepPosition.7"
        defaultMessage="Step 7 of 7 - Receipt"
    />
);

export const NotAuthorized = (
    <FormattedMessage
        id="message.not_authorized"
        defaultMessage="Not Authorized"
    />
);
