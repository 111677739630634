import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { loadTermsOfUse } from "../../../actions/Terms";
import { LinkPrivacyPolicy } from "../../../resources";

export interface IPrivacyPolicyProps { buttonMode?: boolean }
class PrivacyPolicy extends React.Component<IPrivacyPolicyProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
        };
        this.open = this.open.bind(this);
    }


    open(event: any) {
        event.preventDefault();
        window.open("https://www.heartland.us/privacy-policy", "_blank");
    }

    render() {
        const { buttonMode = false } = this.props
        return buttonMode ?
            (<Button bsStyle="link" onClick={this.open}>
                {LinkPrivacyPolicy}
            </Button>)
            :
            (<a onClick={this.open} href="" target="_blank">
                {LinkPrivacyPolicy}
            </a>)
    }
}

const mapStateToProps = (state: any) => ({
    intl: state.intl,
    termsOfUse: state.termsOfUse,
});

const mapDispatchToProps = {
    loadTermsOfUse,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
