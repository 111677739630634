import React from "react";
import { Input } from "./Input";

import { checkLuhn } from "../../utils/Validation";
import {
    IsMasterCard,
    IsVisa,
    IsDiscoverCard,
    IsAmericanExpress,
    generateMaskFromNumber,
} from "../../utils/CardTypes";

export enum CreditCardNumberInputPropsEnum {
    name,
    value,
    enableVisa,
    enableMaster,
    enableDiscover,
    enableAmex,
    format,
    onBlur,
    onChange,
    clearField,
    paymentMethod,
    billTotal,
    checkExpired,
    callCardType,
    handleWalletPaymentSubmitted,
    hideFutureDated,
}

interface ICreditCardNumberInputState {
    format: string;
    showVisa: boolean;
    showMaster: boolean;
    showDiscover: boolean;
    showAmex: boolean;
    hideValue: boolean;
    cardAccepted: boolean;
    cardType: "" | "Visa" | "Mastercard" | "Discover" | "American Express";
}

export class CreditCardNumberInput extends React.Component<
    any,
    ICreditCardNumberInputState
> {
    public static defaultProps: Partial<any> = {
        enableVisa: false,
        enableMaster: false,
        enableDiscover: false,
        enableAmex: false,
    };

    constructor(props: any) {
        super(props);
        const { enableVisa, enableMaster, enableDiscover, enableAmex } = props;
        this.state = {
            format: "9999 9999 9999 9999 999",
            showVisa: false,
            showMaster: false,
            showDiscover: false,
            showAmex: false,
            hideValue: true,
            cardAccepted: true,
            cardType: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleIgnore = this.handleIgnore.bind(this);
    }

    componentWillMount() {
        const {
            value,
            paymentMethod,
            enableVisa,
            enableMaster,
            enableDiscover,
            enableAmex,
        } = this.props;
        if (value.includes("XXXX XXXX XXXX")) {
            switch (paymentMethod) {
                case 1:
                case 11:
                    this.setState({
                        showVisa: true,
                        showMaster: false,
                        showDiscover: false,
                        showAmex: false,
                        format: "",
                        cardAccepted: enableVisa,
                        cardType: "Visa",
                    });
                    break;
                case 2:
                case 12:
                    this.setState({
                        showVisa: false,
                        showMaster: true,
                        showDiscover: false,
                        showAmex: false,
                        format: "",
                        cardAccepted: enableMaster,
                        cardType: "Mastercard",
                    });
                    break;
                case 3:
                case 13:
                    this.setState({
                        showVisa: false,
                        showMaster: false,
                        showDiscover: true,
                        showAmex: false,
                        format: "",
                        cardAccepted: enableDiscover,
                        cardType: "Discover",
                    });
                    break;
                case 4:
                case 14:
                    this.setState({
                        showVisa: false,
                        showMaster: false,
                        showDiscover: false,
                        showAmex: true,
                        format: "",
                        cardAccepted: enableAmex,
                        cardType: "American Express",
                    });
                    break;
                default:
                    this.setState({
                        showVisa: false,
                        showMaster: false,
                        showDiscover: false,
                        showAmex: false,
                        format: "",
                        cardAccepted: true,
                        cardType: "",
                    });
                    break;
            }
        }
    }

    setShowCardType = (paymentMethod: number) => {
        const { enableVisa, enableMaster, enableDiscover, enableAmex } =
            this.props;

        switch (paymentMethod) {
            case 1:
            case 11:
                this.setState({
                    format: "9999 9999 9999 9999 999",
                    showVisa: true,
                    showMaster: false,
                    showDiscover: false,
                    showAmex: false,
                    cardAccepted: enableVisa,
                    cardType: "Visa",
                });
                break;
            case 2:
            case 12:
                this.setState({
                    format: "9999 9999 9999 9999 999",
                    showVisa: false,
                    showMaster: true,
                    showDiscover: false,
                    showAmex: false,
                    cardAccepted: enableMaster,
                    cardType: "Mastercard",
                });
                break;
            case 3:
            case 13:
                this.setState({
                    format: "9999 9999 9999 9999 999",
                    showVisa: false,
                    showMaster: false,
                    showDiscover: true,
                    showAmex: false,
                    cardAccepted: enableDiscover,
                    cardType: "Discover",
                });
                break;
            case 4:
            case 14:
                this.setState({
                    format: "9999 999999 99999",
                    showVisa: false,
                    showMaster: false,
                    showDiscover: false,
                    showAmex: true,
                    cardAccepted: enableAmex,
                    cardType: "American Express",
                });
                break;
            default:
                this.setState({
                    format: "9999 9999 9999 9999 999",
                    showVisa: false,
                    showMaster: false,
                    showDiscover: false,
                    showAmex: false,
                    cardAccepted: false,
                    cardType: "",
                });
                break;
        }
    };

    CheckFieldValidity(element: any) {
        if (checkLuhn(element.value)) {
            if (!this.state.cardAccepted) {
                element.setCustomValidity("form.error.cardNotAccepted");
            } else {
                element.setCustomValidity("");
            }
        } else {
            element.setCustomValidity("form.error.cardNumber");
        }
    }

    handleFocus(event: any) {
        const { name } = this.props;
        if (this.props.clearField) {
            this.props.clearField(name);
        }
        this.setState({
            hideValue: false,
        });
    }

    handleBlur(event: any) {
        if (event.target.value) {
            this.CheckFieldValidity(event.target);
            this.setState({
                hideValue: true,
            });
        }
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    handleIgnore(event: any) {
        event.preventDefault();
    }

    handleChange(event: any) {
        const value = event.target.value;
        const cardTypeIdentified = this.identifyCardTypeFromNumberEntry(value);

        if (!event.target.validity.valid) {
            this.CheckFieldValidity(event.target);
        }

        if (!cardTypeIdentified) {
            if (value.length > 0) {
                this.props.callCardType(value, this.setShowCardType);
            } else {
                this.setState({
                    format: "9999 9999 9999 9999 999",
                    showVisa: true,
                    showMaster: true,
                    showDiscover: true,
                    showAmex: true,
                });
            }
        }

        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    identifyCardTypeFromNumberEntry = (cardNumber: string) => {
        const { enableVisa, enableMaster, enableDiscover, enableAmex } =
            this.props;
        let cardTypeIdentified = false;

        if (IsVisa(cardNumber)) {
            cardTypeIdentified = true;
            this.setState({
                format: "9999 9999 9999 9999 999",
                showVisa: true,
                showMaster: false,
                showDiscover: false,
                showAmex: false,
                cardAccepted: enableVisa,
                cardType: "Visa",
            });
        } else if (IsMasterCard(cardNumber)) {
            cardTypeIdentified = true;
            this.setState({
                format: "9999 9999 9999 9999 999",
                showVisa: false,
                showMaster: true,
                showDiscover: false,
                showAmex: false,
                cardAccepted: enableMaster,
                cardType: "Mastercard",
            });
        } else if (IsDiscoverCard(cardNumber)) {
            cardTypeIdentified = true;
            this.setState({
                format: "9999 9999 9999 9999 999",
                showVisa: false,
                showMaster: false,
                showDiscover: true,
                showAmex: false,
                cardAccepted: enableDiscover,
                cardType: "Discover",
            });
        } else if (IsAmericanExpress(cardNumber)) {
            cardTypeIdentified = true;
            this.setState({
                format: "9999 999999 99999",
                showVisa: false,
                showMaster: false,
                showDiscover: false,
                showAmex: true,
                cardAccepted: enableAmex,
                cardType: "American Express",
            });
        }

        return cardTypeIdentified;
    };

    render() {
        const {
            name,
            enableVisa,
            enableMaster,
            enableDiscover,
            enableAmex,
            value,
        } = this.props;
        const {
            format,
            showVisa,
            showMaster,
            showDiscover,
            showAmex,
            hideValue,
        } = this.state;
        const props: any = Object.assign({}, this.props);
        Object.keys(this.props).forEach((key) => {
            if (key in CreditCardNumberInputPropsEnum) {
                delete props[key];
            }
        });
        const labelIcon = (
            <span>
                {enableVisa && showVisa && (
                    <span>
                        <i className="pay-method-icon pmtype-visa" />
                        &nbsp;{" "}
                    </span>
                )}
                {enableMaster && showMaster && (
                    <span>
                        <i className="pay-method-icon pmtype-mastercard" />
                        &nbsp;{" "}
                    </span>
                )}
                {enableDiscover && showDiscover && (
                    <span>
                        <i className="pay-method-icon pmtype-discover" />
                        &nbsp;{" "}
                    </span>
                )}
                {enableAmex && showAmex && (
                    <span>
                        <i className="pay-method-icon pmtype-amex" />
                        &nbsp;{" "}
                    </span>
                )}
            </span>
        );
        const type = hideValue ? "text" : "tel";
        const inputValue =
            hideValue && checkLuhn(value)
                ? generateMaskFromNumber(value)
                : value;
        const inputFormat =
            hideValue && value && checkLuhn(value)
                ? inputValue.replace(/[\d]/g, "9")
                : format;
        return (
            <Input
                {...props}
                type={type}
                name={name}
                value={inputValue}
                format={inputFormat}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onCut={this.handleIgnore}
                onCopy={this.handleIgnore}
                labelIcon={labelIcon}
                data-cardtype={this.state.cardType}
            />
        );
    }
}
