import { History } from "history";
import React from "react";
import { Checkbox, Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import {
    IAccount,
    IApplication,
    IApplicationRoute,
    IBill,
    IBillType,
    IGeneratedReceiptResponse,
    IInternationalization,
    IPayment,
    IPayor,
    ISettings,
    ITransaction,
    IUser,
} from "../../types";
import { BillGrid } from "../Bill/BillGrid";
import { FinishButton } from "../Input";
import PaymentInformation from "../Verify/PaymentInformation";
import PayorInformation from "../Verify/PayorInformation";
import SuccessfulPayment from "./SuccessfulPanel";
import { RouteComponentProps } from "react-router-dom";

export interface IReceiptPanelProps
    extends RouteComponentProps<IApplicationRoute> {
    application: IApplication;
    settings: ISettings;
    intl: IInternationalization;
    isWebReceipt: boolean;
    billTypes: IBillType[];
    bills: IBill[];
    history: History;
    payment: IPayment;
    payor: IPayor;
    paymentTerms: string;
    transaction: ITransaction;
    userAccounts: IAccount[];
    user: IUser;
    loadBillImage: (
        merchantName: string,
        billId: string,
        application: IApplication
    ) => void;
    clearEbppBills: VoidFunction;
    clear: VoidFunction;
    setIsPaymentCompleted: (isPaymentCompleted: boolean) => void;
    generate: (
        application: IApplication,
        user: IUser,
        param: number,
        locale: string,
        onError: (text: string) => void,
        onSuccess: (result: IGeneratedReceiptResponse) => void
    ) => void;
    refresh: (location: string) => void;
}

class ReceiptPanel extends React.Component<IReceiptPanelProps> {
    constructor(props: IReceiptPanelProps) {
        super(props);
        this.createMarkup = this.createMarkup.bind(this);
    }

    componentWillMount() {
        const { application, settings, transaction } = this.props;
        if (!transaction) {
            const path = `/${settings.merchantName}/InvalidRequest`;
            this.props.history.push(path);
        }

        this.props.clearEbppBills();
    }

    createMarkup() {
        const { paymentTerms } = this.props;
        return { __html: paymentTerms };
    }

    private loadImageForBill = (billId: string) => {
        return this.props.loadBillImage(
            this.props.settings.merchantName,
            billId,
            this.props.application
        );
    };

    render() {
        const {
            application,
            billTypes,
            settings,
            payor,
            payment,
            transaction,
            isWebReceipt,
        } = this.props;
        const labelTermsAgree = (
            <FormattedMessage
                id="payment.terms.agree"
                defaultMessage="I agree to the Terms and Conditions"
            />
        );
        return (
            <div className="margin-top-20">
                <Row>
                    {transaction && (
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <SuccessfulPayment {...this.props} />
                        </Col>
                    )}
                </Row>
                {!isWebReceipt && <FinishButton {...this.props} />}
                <Row>
                    {billTypes && (
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <BillGrid
                                intl={this.props.intl}
                                billTypes={this.props.billTypes}
                                bills={this.props.bills}
                                isEBPP={this.props.application.isEBPP}
                                showEdit={false}
                                loadImageForBill={this.loadImageForBill}
                            />
                        </Col>
                    )}
                </Row>
                <div className="well">
                    <Row>
                        {payor && (
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <PayorInformation
                                    {...this.props}
                                    merchant={settings}
                                    showEdit={false}
                                />
                            </Col>
                        )}
                        {payment && (
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <PaymentInformation
                                    {...this.props}
                                    merchant={settings}
                                    showEdit={false}
                                />
                            </Col>
                        )}
                    </Row>
                </div>
            </div>
        );
    }
}

export default ReceiptPanel;
