import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import {
    Panel,
    Row,
    Col,
    Alert,
} from "react-bootstrap";
import FeeTable from "./FeeTable";
import ApplePayButton from 'apple-pay-button';
import { applePayValidateMerchant } from "../../api/Payment";
import {
    IApplication,
    IPayment,
    IPayor,
    ISettings,
} from "../../types";
import { IFormError } from "../../types/IFormError";
import PaymentTermsAuto from "../Verify/PaymentTermsAuto";

export interface ApplePayPanelProps {
    application: IApplication,
    merchant: ISettings,
    payment: IPayment,
    errors: IFormError,
    onError: (error: string, message: string) => void,
    hideFeePanel: boolean,
    allowedCardNetworks: string[],
    billTotal: number,
    payor: IPayor,
    handleWalletPaymentSubmitted: () => Promise<boolean>,
    setIsPaymentCompleted?: (isPaymentCompleted: boolean) => void;
}

class ApplePayPanel extends React.Component<ApplePayPanelProps, any> {

    public static defaultProps: Partial<any> = {
        hideFeePanel: false,
    };

    handleApplePayButtonClick = () => {
        const {
            merchant,
            payment,
            allowedCardNetworks,
            billTotal,
            setIsPaymentCompleted
        } = this.props;

        const applePayRequest: ApplePayJS.ApplePayPaymentRequest = {
            countryCode: 'US',
            currencyCode: 'USD',
            supportedNetworks: allowedCardNetworks,
            merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
            total: {
                label: merchant.friendlyName,
                amount: (payment as IPayment).total?.toFixed(2) ?? "0.00"
            },
            lineItems: [
                {
                    label: merchant.convenienceFeeLabel ?? "",
                    amount: (payment as IPayment).convenienceFee?.toFixed(2) ?? "0.00"
                },
                {
                    label: "SubTotal",
                    amount: billTotal.toFixed(2)
                }
            ],
            requiredBillingContactFields: this.applePayRequiredFields(),
            billingContact: this.getApplePayBillingContact()
        };

        // Create ApplePaySession
        const session = new ApplePaySession(3, applePayRequest);

        session.onvalidatemerchant = async () => {
            try {
                const merchantSession = await applePayValidateMerchant();
                session.completeMerchantValidation(merchantSession);
            } catch (error) {
                session.abort();
            }
        };

        session.onpaymentauthorized = async (event) => {
            let paymentSuccessful = false; 

            try {
                const paymentData = event.payment;

                if (paymentData) {
                    const address = paymentData.billingContact?.addressLines;

                    payment.firstName = paymentData.billingContact?.givenName ?? "";
                    payment.lastName = paymentData.billingContact?.familyName ?? "";
                    payment.address = (address && address.length > 0 ? address.join(" ") : "");
                    payment.cardNumber = "xxxx xxxx xxxx " + paymentData.token.paymentMethod.displayName.slice(-4);
                    payment.city = paymentData.billingContact?.locality ?? "";
                    payment.country = paymentData.billingContact?.countryCode ?? "";
                    payment.mobilePhone = paymentData.billingContact?.phoneNumber ?? "";
                    payment.phone = paymentData.billingContact?.phoneNumber ?? "";
                    payment.postal = paymentData.billingContact?.postalCode ?? "";
                    payment.state = paymentData.billingContact?.administrativeArea ?? "";
                    payment.type = paymentData.token.paymentMethod.network;
                    payment.applePayToken = JSON.stringify(paymentData.token.paymentData);

                    paymentSuccessful = await this.props.handleWalletPaymentSubmitted() ?? false;

                } else {
                    console.error('Payment processing failed');
                    session.completePayment(ApplePaySession.STATUS_FAILURE);
                }

            } catch (error) {
                console.error('Error during payment authorization', error);
                session.completePayment(ApplePaySession.STATUS_FAILURE);
            }

            if (paymentSuccessful) {
                session.completePayment(ApplePaySession.STATUS_SUCCESS);
                setIsPaymentCompleted && setIsPaymentCompleted(true);

            } else {
                session.completePayment(ApplePaySession.STATUS_FAILURE);
            }
        };

        try {
            session.begin();
        } catch (error) {
            console.error('Error starting Apple Pay session', error);
        }

    };

    private applePayRequiredFields = (): ApplePayJS.ApplePayContactField[] => {
        const { merchant } = this.props;
        const requiredFields: ApplePayJS.ApplePayContactField[] = [];

        // Build required fields for apple pay:
        if (merchant.emailIsRequired) {
            requiredFields.push("email");
        }

        if (merchant.firstNameIsRequired || merchant.lastNameIsRequired) {
            requiredFields.push("name");
        }

        if (merchant.phoneIsRequired) {
            requiredFields.push("phone");
        }

        if (merchant.postalIsRequired) {
            requiredFields.push("postalAddress");
        }

        return requiredFields;
    };

    private getApplePayBillingContact = (): ApplePayJS.ApplePayPaymentContact => {
        return {
            emailAddress: this.props.payor.email,
            phoneNumber: this.props.payor.phone
        };
    }
    

    render() {
        const {
            merchant,
            payment,
            hideFeePanel,
            errors,
            billTotal,
        } = this.props;
      
        return (
            <Panel>
                <Panel.Body>
                    {(<Row>
                        {(
                            <Col style={{ textAlign: 'center' }} xs={12} sm={12} md={12}>
                                <ApplePayButton
                                    onClick={async () => await this.handleApplePayButtonClick()}
                                    buttonStyle='black'
                                    type='pay'
                                    style={{ boxSizing: 'content-box', width: '240px' }}
                                />
                            </Col>
                        )}
                    </Row>)}
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            <br/>
                            <PaymentTermsAuto
                                paymentTermsMode={"applepay"}
                                settings={this.props.merchant}
                            />
                            <br/>
                        </Col>
                    </Row>
                    {!hideFeePanel && (
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <br />
                                <FeeTable
                                    merchant={merchant}
                                    payment={payment}
                                    billTotal={billTotal}
                                />
                            </Col>
                        </Row> 
                    )}
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            {errors.system && (
                                <Alert bsStyle="danger">
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                    />{" "}
                                    {errors.system}
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </Panel.Body>
            </Panel>
        );
    }
}

export default ApplePayPanel;
