import React from "react";
import * as Resources from "../../resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faQuestionCircle,
    faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import {
    InputGroup,
    Popover,
    OverlayTrigger,
    Panel,
    Row,
    Col,
    Image,
    Alert,
    Checkbox,
} from "react-bootstrap";
import {
    TextInput,
    NumberInput,
    CreditCardNumberInput,
    MonthSelectInput,
    YearSelectInput,
} from "../Input";
import { checkLuhn } from "../../utils/Validation";
import { IsAmericanExpress } from "../../utils/CardTypes";
import FeeTable from "./FeeTable";
import FutureDatePayment from "./FutureDatePayment";
import { CheckFieldValidity, isCardExpired } from "../../utils/Validation";

import cvv3 from "../../images/cvv3.svg";
import cvv4 from "../../images/cvv4.svg";

export enum DebitCardPanelPropsEnum {
    intl,
    application,
    merchant,
    isLoggedIn,
    payment,
    errors,
    onBlur,
    onChange,
    onError,
    clearField,
    hideFeePanel,
    allowedCardNetworks,
    hideFutureDated,
    hideCvcPanel,
    readOnlyField,
    showRememberAccount,
    showAccountNickName,
    calculateFee,
    onChecked,
    loadAutoPayTerms,
    maxFuturePaymentDays,
    locale,
}

class DebitCardPanel extends React.Component<any, any> {
    private monthInput: any;

    public static defaultProps: Partial<any> = {
        hideFeePanel: false,
        isLoggedIn: false,
    };

    constructor(props: any) {
        super(props);
        this.state = {
            inputType: "password",
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleFocus = (event: any) => {
        if (event.target.name === "cvc") {
            this.setState({ inputType: "tel" });
        }
    };

    handleBlur(event: any) {
        const {
            application,
            merchant: { merchantName },
            payment,
            calculateFee,
            billTotal,
        } = this.props;
        if (checkLuhn(payment.cardNumber)) {
            if (calculateFee) {
                calculateFee(
                    application,
                    merchantName,
                    "en",
                    billTotal,
                    null,
                    2,
                    payment.cardNumber
                );
            }
            if (
                application.isBillDetail &&
                this.props.merchant.allowAutoBillPay
            ) {
                this.props.loadAutoPayTerms!(
                    application,
                    merchantName,
                    484,
                    7,
                    1
                );
            }
        }
        if (event.target.name === "cvc") {
            this.setState({ inputType: "password" });
        }
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    handleChange(event: any) {
        const { checkExpired, onError, payment } = this.props;
        const field = event.target.name;
        const checkExpiredField =
            checkExpired &&
            (field === "expirationMonth" || field === "expirationYear");
        if (checkExpiredField) {
            if (
                field === "expirationMonth" &&
                isCardExpired(event.target.value, payment.expirationYear)
            ) {
                this.monthInput.setCustomValidity("form.error.expiredCard");
            } else if (
                field === "expirationYear" &&
                isCardExpired(payment.expirationMonth, event.target.value)
            ) {
                this.monthInput.setCustomValidity("form.error.expiredCard");
            } else {
                this.monthInput.setCustomValidity("");
            }
        }
        if (checkExpiredField) {
            CheckFieldValidity(this.monthInput, onError);
        } else if (event.target.validity.valid) {
            CheckFieldValidity(event.target, onError);
        }
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    callCardTypeWrapper = (
        cardNumber: string,
        setShowCardType: (cardType: string) => void
    ) => {
        const {
            application,
            merchant: { merchantName },
        } = this.props;
        this.props.callCardType(
            application,
            merchantName,
            "en",
            2,
            cardNumber,
            setShowCardType
        );
    };

    render() {
        const {
            merchant,
            payment,
            hideFeePanel,
            hideFutureDated,
            errors,
            onChecked,
            billTotal,
        } = this.props;
        const {
            showRememberAccount,
            showAccountNickName,
            hideCvcPanel,
            readOnlyField,
        } = this.props;
        const props: any = Object.assign({}, this.props);
        Object.keys(this.props).forEach((key) => {
            if (key in DebitCardPanelPropsEnum) {
                delete props[key];
            }
        });
        const isAMEX = IsAmericanExpress(payment.cardNumber);
        const popoverTitle = isAMEX
            ? Resources.TooltipHeaderAmex
            : Resources.TooltipHeaderOther;
        const popover = (
            <Popover id="popover-positioned-top" title={popoverTitle}>
                {isAMEX ? (
                    <Image src={cvv4} responsive />
                ) : (
                    <Image src={cvv3} responsive />
                )}
            </Popover>
        );
        const feedback = (
            <InputGroup.Addon>
                <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popover}
                >
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </OverlayTrigger>
            </InputGroup.Addon>
        );
        const isRequired = payment.method === "debit";
        const monthRef = (select: any) => {
            this.monthInput = select;
        };
        return (
            <Panel>
                <Panel.Body>
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            <CreditCardNumberInput
                                {...props}
                                label={Resources.LabelCardNumber}
                                name="cardNumber"
                                value={payment.cardNumber}
                                enableVisa={merchant.visaDebitIsAccepted}
                                enableMaster={
                                    merchant.masterCardDebitIsAccepted
                                }
                                enableDiscover={
                                    merchant.discoverDebitIsAccepted
                                }
                                enableAmex={
                                    merchant.americanExpressDebitIsAccepted
                                }
                                paymentMethod={payment.paymentMethod}
                                error={errors.cardNumber}
                                onBlur={this.handleBlur}
                                onChange={this.props.onChange}
                                onError={this.props.onError}
                                readOnly={readOnlyField}
                                required={isRequired}
                                callCardType={this.callCardTypeWrapper}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            <TextInput
                                label={Resources.LabelNameOnCard}
                                name="nameOnAccount"
                                value={payment.nameOnAccount}
                                error={errors.nameOnAccount}
                                onBlur={this.props.onBlur}
                                onChange={this.props.onChange}
                                onError={this.props.onError}
                                readOnly={readOnlyField}
                                required={isRequired}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            {...(hideCvcPanel
                                ? { md: 7, sm: 7, xs: 7 }
                                : { md: 5, sm: 7, xs: 7 })}
                        >
                            <MonthSelectInput
                                label={Resources.LabelMonth}
                                name="expirationMonth"
                                value={payment.expirationMonth}
                                error={errors.expirationMonth}
                                onBlur={this.props.onBlur}
                                onChange={this.handleChange}
                                onError={this.props.onError}
                                required={isRequired}
                                inputRef={monthRef}
                            />
                        </Col>
                        <Col
                            {...(hideCvcPanel
                                ? { md: 5, sm: 5, xs: 5 }
                                : { md: 3, sm: 5, xs: 5 })}
                        >
                            <YearSelectInput
                                label={Resources.LabelYear}
                                name="expirationYear"
                                value={payment.expirationYear}
                                error={errors.expirationYear}
                                onBlur={this.props.onBlur}
                                onChange={this.handleChange}
                                onError={this.props.onError}
                                required={isRequired}
                            />
                        </Col>
                        {!hideCvcPanel && (
                            <Col xs={12} sm={12} md={4}>
                                <NumberInput
                                    label={Resources.LabelCode}
                                    name="cvc"
                                    value={payment.cvc}
                                    type={this.state.inputType}
                                    minLength={isAMEX ? 4 : 3}
                                    maxLength={isAMEX ? 4 : 3}
                                    error={errors.cvc}
                                    onBlur={this.handleBlur}
                                    onFocus={this.handleFocus}
                                    onChange={this.props.onChange}
                                    onError={this.props.onError}
                                    placeholder="CVC"
                                    required={isRequired}
                                    addonAfter={feedback}
                                />
                            </Col>
                        )}
                    </Row>
                    {showRememberAccount && (
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <Checkbox
                                    checked={
                                        payment.saveAccountInMyAccountWallet
                                    }
                                    name="saveAccountInMyAccountWallet"
                                    onChange={onChecked}
                                >
                                    {Resources.MessageRememberCard}
                                </Checkbox>
                            </Col>
                        </Row>
                    )}
                    {!hideFutureDated && (
                        <Row>
                            <Col xs={7} sm={7} md={5}>
                                <FutureDatePayment
                                    name="futurePaymentDate"
                                    locale={this.props.locale}
                                    label={merchant.futureDatedPaymentLabel}
                                    selectedDate={payment.futurePaymentDate}
                                    maxNumberOfDays={
                                        this.props.maxFuturePaymentDays
                                    }
                                    onChanged={this.props.onChange}
                                />
                            </Col>
                        </Row>
                    )}
                    {!hideFeePanel && (
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <br />
                                <FeeTable
                                    merchant={merchant}
                                    payment={payment}
                                    billTotal={billTotal}
                                />
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            {errors.system && (
                                <Alert bsStyle="danger">
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                    />{" "}
                                    {errors.system}
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </Panel.Body>
            </Panel>
        );
    }
}

export default DebitCardPanel;
